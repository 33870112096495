import "./App.css";
import React from "react";
import Router from "./Router";
import firebase from "./firebase";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return <Router></Router>;
}

export default App;

import firebase from "firebase";

var firebaseConfig = {
  apiKey: "AIzaSyBASPEv6zt3u0TAl1olRAVqYsEAekQ6WpQ",
  authDomain: "schoolyard-a2d00.firebaseapp.com",
  databaseURL: "https://schoolyard-a2d00-default-rtdb.firebaseio.com",
  projectId: "schoolyard-a2d00",
  storageBucket: "schoolyard-a2d00.appspot.com",
  messagingSenderId: "148591933528",
  appId: "1:148591933528:web:cd4ebbadf29c9ef78af22d",
  measurementId: "G-5VW839SWC0"
};




// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;

// const messaging = firebase.messaging();
// messaging.onMessage((payload) => {
//   // Update the UI to include the received message.
//   alert(payload.notification.body);
// });
import React, { useState } from "react";
import Recorder from "../components/Recorder";
const RecorderPage = () => {
  return (
    <React.Fragment>
      <Recorder></Recorder>
    </React.Fragment>
  );
};

export default RecorderPage;

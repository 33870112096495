import React, { useState, useEffect } from "react";
import firebase from "../firebase";
import "./Styles.css";
import logoSchoolyard from "../shared/resources/logoSchoolyard.svg";
import { Redirect, useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";


const Login = () => {
  const history = useHistory();
  const [log, setLog] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
//   const [user, setUser] = useState(null);

  // const [navTocken, setTocken] = useState('');
  const [value, setValue] = useState();

//   var navTocken = "";
  let numberCollections = [];
  let userExist = false;

  const ChangePageToAddPhone = () => {
    setLog("addPhone");
  };

  const phoneNumberAdd = (event) => {
    setPhoneNumber(event.target.value);
  };
  //to set the value of the input into the state phonenumber

  function savingNotificationToken(token) {
    //TODO
    //1.We have to define if there will be one device per user o more
    //2.If only one device then just update and keep only one token browser
    //3.If more then one then just insert a new one

    //For demo just one attached to user collection
    console.log("updating the browser token for this user");
  }

  // function notificationPermission(e) {
  //     Notification.requestPermission().then((permission) => {
  //       if (permission === "granted") {
  //         console.log("Notification permission granted.");

  //         const messaging = firebase.messaging();
  //         messaging
  //           .getToken({
  //             vapidKey:
  //               "BMeqcmFxE2nUFpTEJJNWqdodv3_DzxVORKFzVvpbhMZT1WV55r_LGSnvtKgaZyMsKdi8UFOgAXCliY2PGjzF40E",
  //           })
  //           .then((currentToken) => {
  //             if (currentToken) {
  //             //   savingNotificationToken(currentToken);
  //               console.log("Notification token: " + currentToken);
  //             //   navTocken = currentToken;
  //             // let coderecived = document.getElementById("codigorecibido").value
  //             // if (coderecived === null) return;
  //             // e.confirm(coderecived).then((result) => {
  //             //     firebase.database().ref('users/' + result.user.uid).set({
  //             //         Id: result.user.uid,
  //             //         Phone: result.user.phoneNumber,
  //             //         FirstName: "",
  //             //         LastName: "",
  //             //         RefId: "",
  //             //         TockenNavegator: currentToken,
  //             //         profileStepOptions: "",
  //             //         profileResult: "",
  //             //         PlanName: "",
  //             //         ValidatorActivityPhoto: ""
  //             //       });
  //             // }).catch((error) => {
  //             //     console.log(error);
  //             // })
  //             } else {
  //               console.log(
  //                 "No registration token available. Request permission to generate one."
  //               );
  //             }
  //           })
  //           .catch((err) => {
  //             console.log("An error occurred while retrieving token. ", err);
  //           });
  //       } else {
  //         console.log("Unable to get permission to notify.");
  //       }
  //     });
  //   }

  useEffect(() => {
    // firebase.auth().onAuthStateChanged((user) => {
    //   // have the information about the logged user
    //   setUser(user);
    // });
    firebase.analytics().logEvent("Login_page");
  }, []);

  const phoneNumberHandler = () => {
    //Search in db if this number exists
    //TODO
    const dbRef = firebase.database().ref();
    dbRef.child("users").get().then((data) => {
      let dat = data.val()
      for(let i in dat){
        numberCollections.push(dat[i].Phone);
      }
      // console.log("phone number", phoneNumber)
      // console.log("numbers", numberCollections)
      // console.log("strig ", phoneNumber.toString())
      if(numberCollections.includes(phoneNumber.toString())){
        // setUserAlreadyExists(()=>{
        //     return true
        // });
        userExist = true;
        // console.log("ENCONTRADO", userExist)
    }else {
        // setUserAlreadyExists(false);
        // console.log(" fallo", userExist)
    }
    }).catch((error) => {
      console.error(error);
    });

    //store in state that already exists
    // if(numberCollections.includes(phoneNumber.toString())){
    //     setUserAlreadyExists(true);
    //     console.log(userAlreadyExists)
    // }else {
    //     setUserAlreadyExists(false);
    //     console.log(" fallo", userAlreadyExists)
    // }

    let recaptcha = new firebase.auth.RecaptchaVerifier("recaptcha", {
      size: "invisible",
    });
    let number = phoneNumber;
    firebase
      .auth()
      .signInWithPhoneNumber(number, recaptcha)
      .then((e) => {
        setLog("addCode");
        document
          .getElementById("codeInputButton")
          .addEventListener("click", function () {
            //notificationPermission(e);
            let coderecived = document.getElementById("codigorecibido").value;
            if (coderecived === null) return;
            e.confirm(coderecived)
              .then((result) => {
                if (userExist) {
                  //redirect to profile
                //   history.push("/Profile");
                setLog("Profile");
                } else {
                  firebase
                    .database()
                    .ref("users/" + result.user.uid)
                    .set({
                      Id: result.user.uid,
                      Phone: result.user.phoneNumber,
                      FirstName: "",
                      LastName: "",
                      RefId: "",
                      profileStepOptions: "",
                      profileResult: "",
                    });

                  //redirecto to onboarding
                //   history.push("/Onboarding");
                setLog("Onboarding");
                }
              })
              .catch((error) => {
                // console.log(error);
              });
          });
      });
  };

  if (log === "Profile") {
    return <Redirect to="/Profile" />;
  }

  if(log === "Onboarding"){
    return <Redirect to="/Onboarding" />;
  }

  if (log === "addCode") {
    return (
      <Container className="px-4 py-5" style={{ height: "100vh" }}>
        <Row className="h-100 justify-content-center">
          <Col className="col-12 text-center px-4">
            <h5 className="my-5">Enter the code<br></br>send to your phone</h5>
            <input className="w-75" type="text" id="codigorecibido" placeholder="code" />
          </Col>
          <Col className="col-9 mt-auto pb-4">
            <button
              id="codeInputButton"
              className="w-100"
              // onClick={phoneNumberHandler}
            >
              Enter
            </button>
          </Col>
        </Row>
      </Container>
    );
  }

  if (log === "addPhone") {
    return (
      <div>
        <Container className="px-4 py-5" style={{ height: "100vh" }}>
          <Row className="h-100 justify-content-center">
            <Col className="col-12 text-center">
              <h1 style={{ color: "red" }}>Welcome!</h1>
              <h3>Registration</h3>
            </Col>
            <Col className="col-12 text-center px-4">
              <h5>Enter your mobile number</h5>
              <small>We will send you a confirmation code</small>
              <PhoneInput
                defaultCountry="US"
                className="mt-4"
                placeholder="Phone number"
                value={phoneNumber}
                onChange={setPhoneNumber}
              />
            </Col>
            <Col className="col-9 mt-auto pb-4">
              <button className="w-100" onClick={phoneNumberHandler}>
                Next
              </button>
              <div id="recaptcha"></div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }

  return (
    <Container className="px-4 py-5" style={{ height: "100vh" }}>
      <Row className="h-100 justify-content-center">
        <Col className="col-12 text-center">
          <img src={logoSchoolyard}></img>
          <h4 className="mt-5">
            The FUN way to remote<br></br> play with your FRIENDS
          </h4>
          <small>
            (and maybe get a little bit<br></br>fitter in the process)
          </small>


            <div style={{padding:"75% 0 0 0",position:"relative"}}>
              <iframe src="https://player.vimeo.com/video/554958394?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" 
              frameBorder="0" 
              allow="autoplay; fullscreen; picture-in-picture" 
              allowfullscreen style={{position:"absolute",top:0,left:0,width:"100%",height:"100%"}} 
              title="SCHOOLYARD TEASER5.mp4">
              </iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>


          {/* <div
            className=" my-5 d-flex justify-content-center align-items-center"
            style={{ backgroundColor: "gray", height: "200px", width: "100%" }}
          >
            video
          </div> */}
        </Col>
        <Col className="col-9 pb-4">
          <button className="w-100" onClick={ChangePageToAddPhone}>
            Sign up with phone
          </button>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;

import React, { useState } from "react";
import Onboardingcmp from "../components/Onboarding";

const Onboarding = () => {
  return (
    <React.Fragment>
      <Onboardingcmp></Onboardingcmp>
    </React.Fragment>
  );
};

export default Onboarding;

import React, { useState } from "react";
import Invitecmp from "../components/Invite";

const Invite = () => {
  return (
    <React.Fragment>
      <Invitecmp></Invitecmp>
    </React.Fragment>
  );
};

export default Invite;

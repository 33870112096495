import Login from "./Pages/Login";
import Onboarding from "./Pages/Onboarding";
import RecorderPage from "./Pages/RecorderPage";
import Invite from "./Pages/Invite";
import Profile from "./Pages/Profile"
import NewTask from "./components/NewTask"

import {
    BrowserRouter,
    Switch,
    Route
} from "react-router-dom";

const Router = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/Onboarding">
                    <Onboarding />
                </Route>
                {/* add taskid with a strict param in the path to catch the planid selected
                in onboarding */}
                <Route path="/Invite/:teammate/:taskid">
                    <Invite />
                </Route>
                {/* add planid with a strict param in the path to recorder the audio and send to the
                selected plan */}
                <Route path="/Audio/:InviteId/:PlanId">
                    <RecorderPage />
                </Route>
                <Route path="/Profile">
                    <Profile />
                </Route>
                <Route path="/NewTask">
                    <NewTask />
                </Route>
                <Route path="/">
                    <Login />
                </Route>
            </Switch>
        </BrowserRouter>
    )
}

export default Router;
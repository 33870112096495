import React, { useState, useEffect, useRef, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import "./Recorder.css";
import firebase from "../firebase";
import { Container, Row, Col } from "react-bootstrap";

var gumStream; //stream from getUserMedia()
var recorder; //WebAudioRecorder object
var input; //MediaStreamAudioSourceNode  we'll be recording
var encodingType; //holds selected encoding for resulting audio (file)
var encodeAfterRecord = true; // when to encode
var encodingTypeSelect = "mp3";
var URL = window.URL || window.webkitURL;
// shim for AudioContext when it's not avb.
var AudioContext = window.AudioContext || window.webkitAudioContext;
var audioContext; //new audio context to help us record
const Recorder = (props) => {
  //dentro del componente
  const param = useParams();
  const [form1Display, setform1Display] = useState("flex");
  const [form2Display, setform2Display] = useState("none");
  const [form3Display, setform3Display] = useState("none");
  const [form4Display, setform4Display] = useState("none");
  const [recordingMsgDisplay, setrecordingMsgDisplay] = useState("none");
  const [recordingMsgColor, setrecordingMsgColor] = useState("red");
  const [recordingMsg, setrecordingMsg] = useState("Recording audio...");
  const [btnStart, setbtnStart] = useState("");
  const [btnStop, setbtnStop] = useState("none");
  const [audioTag, setaudioTag] = useState("");
  const [userTask, setuserTask] = useState("");
  const [blogData, setblogData] = useState();
  const [blobName, setblobName] = useState("");
  const [userId, setUserId] = useState("");
  const [userName, setuserName] = useState("");
  const inputName = useRef("");
  //after render the component
  useEffect(() => {
    firebase.analytics().logEvent("Recorder_page");
    //getting the userId from params
    setUserId(param); //actually it has to be param.InviteId
    //Get from user collection his task and Name
    const dbRef = firebase.database().ref();
    dbRef
      .child("users")
      .child("/" + param.InviteId)
      .get()
      .then((data) => {
        if (data.val()) {
          // setuserTask(data.val().TaskName); taskName already doesnt exist, it changed by Plans
          setuserName(data.val().FirstName);
          // console.log(data.val());
        }
      }).then(()=>{
        firebase.database().ref('users/' + param.InviteId + "/Plans/" + param.PlanId).get()
        .then((plan) =>{
          setuserTask(plan.val().Name); //to get the name of the task that the planID do reference
        })
      })
      .catch((error) => {
        // console.error(error);
      });
  }, []);
  // function sendNotification() {
  //   //TODO fetch the token attached to detination userIdToSend
  //   var userToken = [];
  //   var dat = {}
  //   var userNotifToken = ''
  //   const dbRef = firebase.database().ref();
  //   dbRef.child("users").child("/" + userId.InviteId).get().then((data) => {
  //     if (data.exists()) {
  //       dat = data.val();
  //         userToken.push({
  //           tocken: dat.TockenNavegator
  //         })
  //         userNotifToken = userToken[0].tocken;
  //         var data = {
  //           notification: {
  //             title: "Schoolyard",
  //             body: "You have a new motivational audio",
  //             click_action: "https://schoolyard-a2d00.web.app/Profile",
  //             icon: "http://url-to-an-icon/icon.png",
  //           },
  //           to: userNotifToken
  //         };
  //         fetch("https://fcm.googleapis.com/fcm/send", {
  //           method: "POST",
  //           body: JSON.stringify(data),
  //           headers: {
  //             Authorization:
  //               "key=AAAAIpjE-Fg:APA91bHCSHoOwLJBAbR6DMlZJkrl61nhqiS-HqBQc81EWW4JduhFu93wW4MTP1Bw49_DLJ6-z9KjmHSe1V1nW29gZEGwizEkkW73gA5djX4GDvkyTmBo_OKYdb_ifJjfcoRpmvwbfdXw",
  //             "Content-type": "application/json",
  //           },
  //         })
  //           .then((res) => res.json())
  //           .catch((error) => console.error("Error:", error))
  //           .then((response) => console.log("Success:", response));
  //     };
  //   });
  // }
  function hideAllForms() {
    setform1Display("none");
    setform2Display("none");
    setform3Display("none");
    setform4Display("none");
  }
  function startRecording() {
    // console.log("startRecording() called");
    var constraints = { audio: true, video: false };
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function (stream) {
        // console.log(
        //   "getUserMedia() success, stream created, initializing WebAudioRecorder..."
        // );
        //hidding and showing buttons
        setbtnStart("none");
        setrecordingMsgDisplay("");
        setbtnStop("");
        audioContext = new AudioContext();
        gumStream = stream;
        input = audioContext.createMediaStreamSource(stream);
        encodingType = encodingTypeSelect;
        recorder = new window.WebAudioRecorder(input, {
          workerDir: "/", // must end with slash
          encoding: encodingType,
          numChannels: 2, //2 is the default, mp3 encoding supports only 2
          onEncoderLoading: function (recorder, encoding) {
            // show "loading encoder..." display
            // console.log("Loading " + encoding + " encoder...");
          },
          onEncoderLoaded: function (recorder, encoding) {
            // hide "loading encoder..." display
            // console.log(encoding + " encoder loaded");
          },
        });
        recorder.onComplete = function (recorder, blob) {
          // console.log("Encoding complete");
          createDownloadLink(blob, recorder.encoding);
          //encodingTypeSelect.disabled = false;
        };
        recorder.setOptions({
          timeLimit: 120,
          encodeAfterRecord: encodeAfterRecord,
          ogg: { quality: 0.5 },
          mp3: { bitRate: 160 },
        });
        //start the recording process
        recorder.startRecording();
        // console.log("Recording started");
      })
      .catch(function (err) {
        //enable the record button if getUSerMedia() fails
      });
  }
  function stopRecording() {
    // console.log("stopRecording() called");
    //changing recording MSG and hidding stop btn
    setbtnStop("none");
    setrecordingMsg("Procesing audio...");
    setrecordingMsgColor("green");
    //stop microphone access
    gumStream.getAudioTracks()[0].stop();
    //tell the recorder to finish the recording (stop recording + encode the recorded audio)
    recorder.finishRecording();
    // console.log("Recording stopped");
  }
  function createDownloadLink(blob, encoding) {
    const nombreArchivo = new Date().toISOString() + ".mp3";
    var url = URL.createObjectURL(blob);
    var auTag = <audio src={url} controls={true}></audio>;
    //Go to next form
    setaudioTag(auTag);
    hideAllForms();
    setform2Display("flex");
    //store blob and name in state
    setblobName(nombreArchivo);
    setblogData(blob);
  }
  function sendAudio() {
    if (inputName.current.value.trim() == "") {
      alert("Your name is required!");
    } else {
      firebase.analytics().logEvent("Audio_sent");
      const userIdToSend = param.InviteId;
      // console.log("UserID received by params is: " + userIdToSend);
      //Go to next form
      hideAllForms();
      setform3Display("flex");
      const file = blogData;
      const storageRef = firebase.storage().ref("audios/" + blobName);
      const task = storageRef.put(file).then(() => {
        firebase
          .storage()
          .ref("audios/" + blobName)
          .getDownloadURL()
          .then((url) => {
            firebase
              .database()
              .ref("audios/" + userId.InviteId)
              .push({
                AudioUrl: url,
                CreationDateTime: new Date().toString(), //Add a creatin date to order later in profile
                senderName: inputName.current.value.trim(),
                PlanId: userId.PlanId //add planId to filtrer audios in profile
              });
          })
          .catch((error) => {
            // console.log(error);
          });
      });
      //sending push notification
      // sendNotification();
    }
  }
  async function notifyUser() {
    const domain = "https://getschoolyard.com/profile";
    //const uid = userId;
    const shareData = {
      title: "Schoolyard",
      text:
        "Hey!!! " +
        inputName.current.value.trim() +
        " has motivated you in schoolyard ",
      url: domain,
    };
    // console.log(shareData);
    try {
      await navigator.share(shareData);
    } catch (err) {
      // console.log("Error: " + err);
    }
  }
  function lastForm() {
    hideAllForms();
    setform4Display("flex");
  }
  return (
    <React.Fragment>
      <div className="onboardingcls">
        <Container
          className="px-4 py-5"
          style={{ display: form1Display, height: "100vh" }}
        >
          <Row className="h-100 justify-content-center">
            <Col className="col-12 text-center">
              <h2 className="main-title mt-4 mb-1">
                Send {userName}
                <br></br>some support!
              </h2>
              <p className="secondary-title mt-4 mb-0">
                {userName} is going to
              </p>
              <p className="highlight-yellow py-2 px-2 mb-0">{userTask}</p>
              <p className="secondary-title mt-2">tomorrow</p>
              <p className="hint-text mb-4 mt-0">
                Motivate them by leaving an audio message that only plays AFTER
                they have sent you proof they have done it!
              </p>
              <Row>
                <Col
                  style={{
                    display: recordingMsgDisplay,
                    color: recordingMsgColor,
                  }}
                >
                  {recordingMsg}
                </Col>
              </Row>
            </Col>
            <Col className="col-9">
              <button
                className="w-100"
                onClick={startRecording}
                style={{ display: btnStart }}
              >
                Record
              </button>
              <button
                className="w-100"
                onClick={stopRecording}
                style={{ display: btnStop }}
              >
                Stop
              </button>
            </Col>
          </Row>
        </Container>
        <Container
          className="px-4 py-5"
          style={{ display: form2Display, height: "100vh" }}
        >
          <Row className="h-100 justify-content-center">
            <Col className="col-12 text-center">
              <h1 className="main-title mt-4 mb-5">
                Send {userName} some support
              </h1>
              <div>
                <input
                  style={{ width: "86%" }}
                  type="text"
                  className="input"
                  placeholder="Type your first name"
                  ref={inputName}
                />
              </div>
              <div className="mt-4 mb-5" style={{ border: "none" }}>
                <div>{audioTag}</div>
              </div>
            </Col>
            <Col className="col-9">
              <button className="w-100" onClick={sendAudio}>
                Send now
              </button>
            </Col>
          </Row>
        </Container>
        <Container
          className="px-4 py-5"
          style={{ display: form3Display, height: "100vh" }}
        >
          <Row className="h-100 justify-content-center">
            <Col className="col-12 text-center">
              <img
                src="/imgs/smiley.png"
                style={{ width: 30, height: 30 }}
                className="mb-3"
              />
              <small className="d-block">Damn!!! You are a</small>
              <small className="d-block">F*$# motivator</small>
              <Row>
                <Col>
                  <h1 className="main-text my-5">
                    Great your message has been delivered!
                  </h1>
                  <h1 className="main-text my-5" style={{ color: "#EB3A28" }}>
                    Better if you notify {userName}!
                  </h1>
                </Col>
              </Row>
            </Col>
            <Col className="col-9 mt-auto">
              <div>
                <button className="w-100 mb-3" onClick={notifyUser}>
                  Notify {userName}
                </button>
              </div>
              <div>
                <button className="w-100" onClick={lastForm}>
                  Continue
                </button>
              </div>
            </Col>
          </Row>
        </Container>

        {/* last screen */}
        <Container
          className="px-4 py-5"
          style={{ display: form4Display, height: "100vh" }}
        >
          <Row className="h-100 justify-content-center">
            <Col className="col-12 text-center">
              <small className="d-block">Schoolyard</small>
              <Row>
                <Col>
                  <h1 className="main-text my-5">
                    Sign up now<br></br> to find out if<br></br> {userName} did
                    it!
                  </h1>
                  <small className="mmy-5">
                    (and maybe get motivated<br></br>to do something too)
                  </small>
                </Col>
              </Row>
            </Col>
            <Col className="col-9">
              <div>
                <Link to="/Login">
                  <button className="w-100">Sign Up</button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default Recorder;

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Onboarding.css";
import "./Styles/Styles.css";
import welcomeSchoolyard from "../shared/resources/welcomeSchoolyard.svg";
import firebase from "../firebase";
import { Container, Row, Col } from "react-bootstrap";

const NewTaskcmp = () => {
  /*Hide/Show form states*/

  const [form4Display, setform4Display] = useState("flex");

  /*Goals selected states*/

  const [userId, setUser] = useState("");
  
  const history = useHistory();

  var last_Task = ""; //to save the last ID in Plans

  useEffect(() => {
    firebase.analytics().logEvent("New_Task_Page");
    firebase.auth().onAuthStateChanged((user) => {
      // have the information about the logged user
      setUser(user.uid);      
    });
  }, []);

  function goInvite(param) {
    firebase
      .database()
      .ref("users/" + userId + "/Plans")
      .push({
        CreationDateTime: new Date().toString(),
        Name: param,
        ValidatorActivityPhoto: "",
      })
      .then(() => {
        firebase
          .database()
          .ref("users/" + userId + "/Plans")
          .limitToLast(1)
          .once("value")
          .then((last) => {
            var dat = last.val();
            for (var key in dat) {
              last_Task = key; //TO SAVE THE LAST PLANID TO PASS TROUGHT THE HISTORY.PUSH
            }
            var teammate = "";
            history.push(
              "/Invite/" + teammate + "---" + param + "/" + last_Task
            );
          });
      });

   
    //navigation to invite
    //cuando lo quiera utilizar con paramtros query string
    // history.push("/Invite/" + teammate + "---" + param + '/' + last_Task); //add last_task, it has the id plans and will send to the user with "sms"
  }

  return (
    <div className="onboardingcls">
      <Container className="px-4 py-5" style={{ display: form4Display }}>
        <Row className="h-100 justify-content-center">
          <Col className="col-12 text-center">
            <h5 className="section-title my-4">
              What’s your plan<br></br>
              for tomorrow?
            </h5>
            <small>DARE yourself to do something good. Choose one:</small>
          </Col>
          <Col className="col-12 mt-4">
            <Row>
              <Col className={"col text-center goal-cards mx-1 p-2 mb-3"}>
                <small
                  className="uppercase goal-description"
                  onClick={() => {
                    goInvite("Wake up Early");
                  }}
                >
                  WAKE UP EARLY
                </small>
              </Col>
              <Col className={"col text-center goal-cards mx-1 p-2 mb-3"}>
                <small
                  className="uppercase goal-description"
                  onClick={() => {
                    goInvite("Cardio - Walk, Bike, or Run");
                  }}
                >
                  CARDIO - WALK, BIKE, or RUN
                </small>
              </Col>
              <Col className={"col text-center goal-cards mx-1 p-2 mb-3"}>
                <small
                  className="uppercase goal-description"
                  onClick={() => {
                    goInvite("Stretching or Yoga");
                  }}
                >
                  STRETCHING or YOGA
                </small>
              </Col>
            </Row>
            <Row>
              <Col className={"col text-center goal-cards mx-1 p-2 mb-3"}>
                <small
                  className="uppercase goal-description"
                  onClick={() => {
                    goInvite("Lifting");
                  }}
                >
                  LIFTING{" "}
                </small>
              </Col>
              <Col className={"col text-center goal-cards mx-1 p-2 mb-3"}>
                <small
                  className="uppercase goal-description"
                  onClick={() => {
                    goInvite("Eat a !@$ Salad");
                  }}
                >
                  EAT A !@$ SALAD
                  {/* deleted "#" by a bug in the url */}
                </small>
              </Col>
              <Col className={"col text-center goal-cards mx-1 p-2 mb-3"}>
                <small
                  className="uppercase goal-description"
                  onClick={() => {
                    goInvite("Stairs or Hills");
                  }}
                >
                  STAIRS or HILLS
                </small>
              </Col>
            </Row>
            <Row>
              <Col className={"col text-center goal-cards mx-1 p-2 mb-3"}>
                <small
                  className="uppercase goal-description"
                  onClick={() => {
                    goInvite("Just Show Up");
                  }}
                >
                  JUST SHOW UP
                </small>
              </Col>
              <Col className={"col text-center goal-cards mx-1 p-2 mb-3"}>
                <small
                  className="uppercase goal-description"
                  onClick={() => {
                    goInvite("Go To Bed Early");
                  }}
                >
                  GO TO BED EARLY{" "}
                </small>
              </Col>
              <Col className={"col text-center goal-cards mx-1 p-2 mb-3"}>
                <small
                  className="uppercase goal-description"
                  onClick={() => {
                    goInvite("Unplug or Meditate");
                  }}
                >
                  UNPLUG or MEDITATE
                </small>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NewTaskcmp;

import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import "./Invite.css";
import "./Styles/Styles.css";
import firebase from "../firebase";
import { Container, Row, Col } from "react-bootstrap";

const Invitecmp = () => {
  const param = useParams();
  const planID = param.taskid; //to save the last plansID and send in the message
  const teamMate = param.teammate.split("---")[0];
  const task = param.teammate.split("---")[1];
  const [userId, setuserId] = useState("");
  const [currentUserName, setCurrentUserName] = useState("");

  var displayTeammate = "block";
  if (teamMate == "") displayTeammate = "none";

  useEffect(() => {
    firebase.analytics().logEvent("Invite_page");
    //getting the userId from Firebase
    firebase.auth().onAuthStateChanged((user) => {
      // tiene la informacion del usuario logueado
      setuserId(user.uid);

      const dbRef = firebase.database().ref();
      dbRef
        .child("users")
        .child("/" + user.uid)
        .get()
        .then((data) => {
          if (data.val()) {
            setCurrentUserName(data.val().FirstName);
          }
        })
        .catch((error) => {
          // console.error(error);
        });
    });
    //TODO
    //Passing to state
  }, []);

  async function enviarLink() {
    const domain = "https://getschoolyard.com/Audio/";
    const uid = userId;

    const shareData = {
      title: "Schoolyard",
      text:
        "Hey! I dared myself to " +
        task +
        " tomorrow --- help make sure I do it by leaving me an audio message I won't get unless I do it-- no signup req'd! ",
      url: domain + uid + "/" + planID, //add the planID to when a person will recorder a motivation can send it to the exact task
    };
    console.log(shareData);
    try {
      await navigator.share(shareData);
    } catch (err) {}
  }

  return (
    <Container className="px-4 py-5" style={{ backgroundColor: "#FFD233" }}>
      <Row className="h-100 justify-content-center">
        <Col className="col-12 text-center yellow-bkg pb-4">
          <p className="section-title-white">
            Now recruit the right teammates
            <br />
            to hold you accountable based
            <br />
            on you being
          </p>
          <h4
            className="mb-5 py-3"
            style={{ backgroundColor: "#33C1D8", display: displayTeammate }}
          >
            {teamMate}
          </h4>
          <p>
            You have 5 invitees,<br></br> CHOOSE WISELY
          </p>
          <small>
            No sign up req’d for teammates to support you schoolyard is free to
            play!
          </small>
        </Col>
        <Col className="col-12 yellow-bkg pt-4 text-center">
          <p className="mb-1">
            TAG YOUR FRIEND THAT IS A<br></br>
          </p>
          <div className="w-100 d-flex justify-content-center friend-role-contianer">
            <p className="friend-role">TEAM PLAYER</p>
          </div>
          <button className="btn-black w-100 mb-4" onClick={enviarLink}>
            Add player
          </button>
          <p className="mb-1">
            TAG YOUR FRIEND THAT IS A<br></br>
          </p>
          <div className="w-100 d-flex justify-content-center friend-role-contianer">
            <p className="friend-role">OBLIGATOR</p>
          </div>
          <button className="btn-black w-100 mb-4" onClick={enviarLink}>
            Add player
          </button>
          <p className="mb-1">
            TAG YOUR FRIEND THAT IS A<br></br>
          </p>
          <div className="w-100 d-flex justify-content-center friend-role-contianer">
            <p className="friend-role">ASS DRAGGER</p>
          </div>
          <button className="btn-black w-100 mb-4" onClick={enviarLink}>
            Add player
          </button>
          <p className="mb-1">
            TAG YOUR FRIEND THAT IS A<br></br>
          </p>
          <div className="w-100 d-flex justify-content-center friend-role-contianer">
            <p className="friend-role">CHEERLEADER</p>
          </div>
          <button className="btn-black w-100 mb-4" onClick={enviarLink}>
            Add player
          </button>
          <p className="mb-1">
            TAG YOUR FRIEND THAT IS A<br></br>
          </p>
          <div className="w-100 d-flex justify-content-center friend-role-contianer">
            <p className="friend-role">ENERGIZER BUNNY</p>
          </div>
          <button className="btn-black w-100 mb-4" onClick={enviarLink}>
            Add player
          </button>
        </Col>
        <Col className="col-12 yellow-bkg pt-4">
          <Link to="/Profile" style={{ color: "black" }}>
            FINISHED, TAKE ME TO MY SCHOOLYARD
          </Link>
        </Col>
      </Row>
    </Container>
  );
};

export default Invitecmp;

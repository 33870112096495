import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "./Onboarding.css";
import "./Styles/Styles.css";
import dominator from "../shared/resources/dominator.jpg";
import welcomeSchoolyard from "../shared/resources/welcomeSchoolyard.svg";
import firebase from "../firebase";
import { Container, Row, Col } from "react-bootstrap";

const Onboardingcmp = () => {
  /*Hide/Show form states*/
  const [form1Display, setform1Display] = useState("flex");
  const [form2Display, setform2Display] = useState("none");
  const [form3Display, setform3Display] = useState("none");
  const [form4Display, setform4Display] = useState("none");

  /*Goals selected states*/
  const [goal1Display, setgoal1Display] = useState("");
  const [goal2Display, setgoal2Display] = useState("");
  const [goal3Display, setgoal3Display] = useState("");
  const [goal4Display, setgoal4Display] = useState("");
  const [teammate, setTeammate] = useState("");
  const [userName, setUserName] = useState("");
  const [userId, setUser] = useState("");

  const history = useHistory();

  var last_Task = "" //to save the last ID in Plans

  // function savingNotificationToken(token) {
  //   //TODO
  //   //1.We have to define if there will be one device per user o more
  //   //2.If only one device then just update and keep only one token browser
  //   //3.If more then one then just insert a new one

  //   //For demo just one attached to user collection
  //   console.log("updating the browser token for this user");
  // }

  // function notificationPermission() {
  //   Notification.requestPermission().then((permission) => {
  //     if (permission === "granted") {
  //       console.log("Notification permission granted.");

  //       const messaging = firebase.messaging();
  //       messaging
  //         .getToken({
  //           vapidKey:
  //             "BMeqcmFxE2nUFpTEJJNWqdodv3_DzxVORKFzVvpbhMZT1WV55r_LGSnvtKgaZyMsKdi8UFOgAXCliY2PGjzF40E",
  //         })
  //         .then((currentToken) => {
  //           if (currentToken) {
  //             savingNotificationToken(currentToken);
  //             console.log("Notification token: " + currentToken);
  //           } else {
  //             console.log(
  //               "No registration token available. Request permission to generate one."
  //             );
  //           }
  //         })
  //         .catch((err) => {
  //           console.log("An error occurred while retrieving token. ", err);
  //         });
  //     } else {
  //       console.log("Unable to get permission to notify.");
  //     }
  //   });
  // }

  function nameHanddler(event) {
    setUserName(event.target.value);
  }

  function hideAllForms() {
    setform1Display("none");
    setform2Display("none");
    setform3Display("none");
    setform4Display("none");
  }

  function cleanAllGoals() {
    setgoal1Display("");
    setgoal2Display("");
    setgoal3Display("");
    setgoal4Display("");
  }

  function goNext(next, TM) {
    if (next == 2) {
      // notificationPermission();
      if (userName.trim() == "") {
        alert("Your name is required!");
      } else {
        firebase.analytics().logEvent("Onboardin_1");
        hideAllForms();
        setform2Display("flex");
      }
    } else if (next == 3) {
      firebase.analytics().logEvent("Onboardin_2");
      hideAllForms();
      setform3Display("flex");
      setTeammate(TM);
    } else if (next == 4) {
      firebase.analytics().logEvent("Onboardin_3");
      hideAllForms();
      setform4Display("flex");
    }
  }

  useEffect(() => {
    firebase.analytics().logEvent("Onboarding_page");
    firebase.auth().onAuthStateChanged((user) => {
      // have the information about the logged user
      setUser(user.uid);

    });
  }, []);

  function goInvite(param) {
    //send to user colection task
    firebase
      .database()
      .ref("users/" + userId)
      .update(
        {
          FirstName: userName,
          // TaskName: param, move to the next call of firebase with the push
        },
        (error) => {
          if (error) {
            // The write failed...
          } else {
            // Data saved successfully!
          }
        } //PUSH THE TASK INTO THE PLANS IN THE USERS COLLECTION
      ).then(() => {
        firebase.database().ref('users/' + userId + '/Plans').push({
          CreationDateTime: new Date().toString(),
          Name: param,
          ValidatorActivityPhoto: ""
        })
      }).then(() => {
        firebase.database().ref('users/' + userId + '/Plans').limitToLast(1).once("value")
          .then((last) => {
            var dat = last.val()
            for (var key in dat) {
              last_Task = key; //TO SAVE THE LAST PLANID TO PASS TROUGHT THE HISTORY.PUSH
            }
            history.push("/Invite/" + teammate + "---" + param + '/' + last_Task);
          })
          
      })
    //navigation to invite
    //cuando lo quiera utilizar con paramtros query string
    // history.push("/Invite/" + teammate + "---" + param + '/' + last_Task); //add last_task, it has the id plans and will send to the user with "sms"
  }

  return (
    <div className="onboardingcls">
      <Container
        className="px-4 py-5"
        style={{ display: form1Display, height: "100vh" }}
      >
        <Row className="h-100 justify-content-center">
          <Col className="col-12 text-center">
            <p className="section-title mb-0">What is your goal right now?</p>
            <h3 className="handwrited py-4">
              What is <br />
              <span className="red-handwrited">your GOAL?</span>
            </h3>
          </Col>
          <Col className="col-12 text-center mb-3">
            <Row className="justify-content-center mb-4">
              <Col className="col-10">
                <input
                  type="text"
                  placeholder="Type your first name"
                  className="input mb-0"
                  onChange={nameHanddler}
                />
              </Col>
            </Row>
            <Row className="mx-3">
              <Col
                className={"card py-3 mb-3 col-12 select-goal " + goal1Display}
                onClick={() => {
                  goNext(2);
                }}
              >
                <h6 className="mb-0 goal">📈 Enhance daily habits</h6>
                <small className="mb-0 goal-description">
                  (and have fun doing it)
                </small>
              </Col>
              <Col
                className={"card py-3 mb-3 col-12 select-goal " + goal2Display}
                onClick={() => {
                  goNext(2);
                }}
              >
                <h6 className="mb-0 goal">📉 Lose Weight For Good</h6>
                <small className="mb-0 goal-description">
                  (and have fun doing it)
                </small>
              </Col>
              <Col
                className={"card py-3 mb-3 col-12 select-goal " + goal3Display}
                onClick={() => {
                  goNext(2);
                }}
              >
                <h6 className="mb-0 goal">🚀 Max Performance</h6>
                <small className="mb-0 goal-description">
                  (and have fun doing it)
                </small>
              </Col>
              <Col
                className={"card py-3 mb-3 col-12 select-goal " + goal4Display}
                onClick={() => {
                  goNext(2);
                }}
              >
                <h6 className="mb-0 goal">🙌 Just Cheer Others On</h6>
                <small className="mb-0 goal-description">
                  (and have fun doing it)
                </small>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container className="px-4 py-5" style={{ display: form2Display }}>
        <Row className="h-100 justify-content-center">
          <Col className="col-12 text-center">
            <small>
              The #1 predictor of success<br></br>is having a GREAT team...
              <br></br>
              let’s build your Schoolyard team!
            </small>
            <h5 className="section-title my-4">
              Which kind of<br></br>teammate are you?
            </h5>
          </Col>
          <Col className="col-12">
            <Row>
              <Col
                className={"col text-center goal-cards mx-1 p-2 mb-3"}
                onClick={() => {
                  goNext(3, "Cheerleader");
                }}
              >
                <small className="uppercase goal-description">
                  📣<br></br>
                  CHEERLEADER{" "}
                </small>
              </Col>
              <Col
                className={"col text-center goal-cards mx-1 p-2 mb-3"}
                onClick={() => {
                  goNext(3, "Energizer Bunny");
                }}
              >
                <small className="uppercase goal-description">
                  🐰<br></br>
                  ENERGIZER<br></br>
                  BUNNY
                </small>
              </Col>
              <Col
                className={"col text-center goal-cards mx-1 p-2 mb-3"}
                onClick={() => {
                  goNext(3, "Lone Wolf");
                }}
              >
                <small className="uppercase goal-description">
                  🐺<br></br>
                  LONE WOLF
                </small>
              </Col>
            </Row>
            <Row>
              <Col
                className={"col text-center goal-cards mx-1 p-2 mb-3"}
                onClick={() => {
                  goNext(3, "Intentioner");
                }}
              >
                <small className="uppercase goal-description">
                  🎀<br></br>
                  GOOD<br></br>INTENTIONER
                </small>
              </Col>
              <Col
                className={"col text-center goal-cards mx-1 p-2 mb-3"}
                onClick={() => {
                  goNext(3, "Ass Dragger");
                }}
              >
                <small className="uppercase goal-description">
                  🍑<br></br>
                  ASS DRAGGER
                </small>
              </Col>
              <Col
                className={"col text-center goal-cards mx-1 p-2 mb-3"}
                onClick={() => {
                  goNext(3, "Comeback Kid");
                }}
              >
                <small className="uppercase goal-description">
                  🏆<br></br>COMEBACK KID
                </small>
              </Col>
            </Row>
            <Row>
              <Col
                className={"col text-center goal-cards mx-1 p-2 mb-3"}
                onClick={() => {
                  goNext(3, "Team Player");
                }}
              >
                <small className="uppercase goal-description">
                  🐝<br></br>TEAM PLAYER
                </small>
              </Col>
              <Col
                className={"col text-center goal-cards mx-1 p-2 mb-3"}
                onClick={() => {
                  goNext(3, "Obligator");
                }}
              >
                <small className="uppercase goal-description">
                  🔍<br></br>OBLIGATOR
                </small>
              </Col>
              <Col
                className={"col text-center goal-cards mx-1 p-2 mb-3"}
                onClick={() => {
                  goNext(3, "Dominator");
                }}
              >
                <small className="uppercase goal-description">
                  💀<br></br>DOMINATOR
                </small>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Container
        className="px-4 py-5"
        style={{ height: "100vh", display: form3Display }}
      >
        <Row className="h-100 justify-content-center">
          <Col className="col-12">
            <img src={welcomeSchoolyard} className="welcomeSchoolyardpic"></img>
          </Col>
          <Col className="text-center">
            <h4>
              Schoolyard works by starting with a DARE - you dare yourself to do
              something and then get your teammates to hold you accountable and
              cheer you on until you do it.
            </h4>
          </Col>
          <Col className="col-9 mt-auto">
            <button
              className="w-100"
              onClick={() => {
                goNext(4);
              }}
            >
              Decalare your activity
            </button>
          </Col>
        </Row>
      </Container>

      <Container className="px-4 py-5" style={{ display: form4Display }}>
        <Row className="h-100 justify-content-center">
          <Col className="col-12 text-center">
            <h5 className="section-title my-4">
              What’s your plan<br></br>
              for tomorrow?
            </h5>
            <small>
              DARE yourself to do something good.  Choose one:
            </small>
          </Col>
          <Col className="col-12 mt-4">
            <Row>
              <Col className={"col text-center goal-cards mx-1 p-2 mb-3"}>
                <small className="uppercase goal-description"
                  onClick={() => { goInvite("Wake up Early") }}>
                  WAKE UP EARLY
                  </small>
              </Col>
              <Col className={"col text-center goal-cards mx-1 p-2 mb-3"}>
                <small className="uppercase goal-description"
                  onClick={() => { goInvite("Cardio - Walk, Bike, or Run") }}>
                  CARDIO - WALK, BIKE, or RUN
                  </small>
              </Col>
              <Col className={"col text-center goal-cards mx-1 p-2 mb-3"}>
                <small className="uppercase goal-description"
                  onClick={() => { goInvite("Stretching or Yoga") }}>
                  STRETCHING or YOGA
                  </small>
              </Col>
            </Row>
            <Row>
              <Col className={"col text-center goal-cards mx-1 p-2 mb-3"}>
                <small className="uppercase goal-description"
                  onClick={() => { goInvite("Lifting") }}>LIFTING </small>
              </Col>
              <Col className={"col text-center goal-cards mx-1 p-2 mb-3"}>
                <small className="uppercase goal-description"
                  onClick={() => { goInvite("Eat a !@$ Salad") }}>
                  EAT A !@$ SALAD
                  {/* deleted "#" by a bug in the url */}
                  </small>
              </Col>
              <Col className={"col text-center goal-cards mx-1 p-2 mb-3"}>
                <small className="uppercase goal-description"
                  onClick={() => { goInvite("Stairs or Hills") }}>
                  STAIRS or HILLS
                  </small>
              </Col>
            </Row>
            <Row>
              <Col className={"col text-center goal-cards mx-1 p-2 mb-3"}>
                <small className="uppercase goal-description"
                  onClick={() => { goInvite("Just Show Up") }}>
                  JUST SHOW UP
                  </small>
              </Col>
              <Col className={"col text-center goal-cards mx-1 p-2 mb-3"}>
                <small className="uppercase goal-description"
                  onClick={() => { goInvite("Go To Bed Early") }}>
                  GO TO BED EARLY{" "}
                </small>
              </Col>
              <Col className={"col text-center goal-cards mx-1 p-2 mb-3"}>
                <small className="uppercase goal-description"
                  onClick={() => { goInvite("Unplug or Meditate") }}>
                  UNPLUG or MEDITATE
                  </small>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Onboardingcmp;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Profile.css";
import "./Styles/Styles.css";
import welcomeSchoolyard from "../shared/resources/welcomeSchoolyard.svg";
import firebase from "../firebase";
import { Container, Row, Col } from "react-bootstrap";
import Moment from "react-moment";

const Profilecmp = (props) => {
  //dentro del componente

  // const [userId, setuserId] = useState("");
  const [taskName, settaskName] = useState([]);
  const [form1Display, setform1Display] = useState("flex");
  const [form2Display, setform2Display] = useState("none");
  const [displayBack, setdisplayBack] = useState("none");

  const [audiosList, setaudiosList] = useState([]);
  const [taskList, setTaskList] = useState([]);
  var response = []; //to save the audios from the database
  var dat = {}; // to save the data from the get in firebase
  var userId = "";
  //after render the component
  useEffect(() => {
    firebase.analytics().logEvent("Profile_page");
    //Connect to Firebase and get auth uid
    //TODO
    firebase.auth().onAuthStateChanged((user) => {
      // have the data of logged user
      userId = user.uid;

      const userList = [];

      firebase
        .database()
        .ref("users/" + user.uid + "/Plans")
        .get()
        .then((res) => {
          var data = res.val();
          for (var key in data) {
            userList.push({
              key: key,
              task: data[key].Name,
              date: data[key].CreationDateTime,
            });
          }
          userList.reverse(); //to sort the plans by date
          var allTask = userList.map((Task) => {
            return (
              <Col
                className="col-10"
                onClick={() => {
                  getAudios(Task.key);
                }}
                key={Task.key}
              >
                <Moment fromNow>{Task.date}</Moment>
                <div className="card new-motivation py-3 mb-3">{Task.task}</div>
              </Col>
            );
          });
          setTaskList(allTask);

          // console.log(userList);
        });
      // --------------------------------------------------------------- below is the old method
      // const dbRef = firebase.database().ref();
      // dbRef
      //   .child("users")
      //   .child("/" + user.uid)
      //   .get()
      //   .then((data) => {
      //     if (data.val()) {
      //       settaskName(data.val().TaskName);
      //     }
      //   })
      //   .catch((error) => {
      //     console.error(error);
      //   });
    });
    //setting userId
    // setuserId();  //original roger
  }, []);

  function hideAllForms() {
    setform1Display("none");
    setform2Display("none");
  }

  function getAudios(param) {
    // add param to compare the planID and filtred the audios by plan
    hideAllForms();
    setform2Display("");
    setdisplayBack("block");

    //Get audios from tasks by userID

    const dbRef = firebase.database().ref("audios/" + userId);
    dbRef
      .get()
      .then((data) => {
        if (data.exists()) {
          dat = data.val();
          for (var key in dat) {
            if (param === dat[key].PlanId) {
              response.push({
                url: dat[key].AudioUrl,
                from: dat[key].senderName,
                audioDate: dat[key].CreationDateTime,
              });
            }
          }
          response.reverse(); //to get the audios in order from most recent to old
          var list = response.map((el) => {
            return (
              <div className="goals">
                <Moment fromNow>{el.audioDate}</Moment>
                <br></br>
                <span style={{ color: "black" }}>{el.from}</span>
                <audio
                  style={{ width: "100%" }}
                  src={el.url}
                  controls={true}
                ></audio>
              </div>
            );
          });
          if (response.length == 0) {
            setaudiosList(["No audios yet"]);
          } else {
            setaudiosList(list);
          }
        } else {
          setaudiosList(["No audios yet"]);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    //if response is empty

    //Map the response
    if (response.length === 0) {
      setaudiosList(["Loading..."]);
    }
  }

  function goBack(){
    window.location.reload();
  }

  return (
    <React.Fragment>
      <Container className="px-4 py-5 height-100">
        <Row
          className="h-100 justify-content-center text-center"
          style={{ display: form1Display }}
        >
          <Col className="col-12 text-center px-4 mb-3">
            <img src={welcomeSchoolyard} className="welcomeSchoolyardpic"></img>
            <p className="mt-5">
              You have some motivation<br></br>from a friend!
            </p>
          </Col>
          {/* add moment to see the time that passed
          add a .map to see all the task in order from recent to old */}
          {/* <Col className="col-10" onClick={()=>{getAudios(taskN[0].key)}}>
            <Moment fromNow></Moment> 
            <div className="card new-motivation py-3 mb-3">{taskN[0]}</div>
          </Col> */}
          {taskList}
        </Row>
        <Row
          className="h-100 justify-content-center text-center"
          style={{ display: form2Display }}
        >
          <Col className="col-12 text-center px-4 mb-4">
            <p className="section-title-white">
              Way to go!<br></br> you have motivation
            </p>
          </Col>
          <Col className="col-12">
            <h4 className="mb-4">{taskName}</h4>
            {audiosList}
          </Col>
        </Row>
        <Row className="h-100 justify-content-center">
          <Col className="col-9 mt-auto">
            <Link to="/NewTask">
              <button className="w-100 mt-5">Decalare your activity</button>
            </Link>
          </Col>
        </Row>
        <Row className="h-100 justify-content-center" >
          <Col
            className="col-12 mt-auto"
            style={{ textAlign: "center", paddingTop: "20px",display:displayBack }}
          >
            <div  style={{  color: "#33c1d8",cursor:"pointer" }} onClick={goBack}>
              GO BACK
            </div>
          </Col>
        </Row>
      </Container>
      <Container></Container>
    </React.Fragment>
  );
};

export default Profilecmp;

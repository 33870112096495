import React, { useState } from "react";
import Profilecmp from "../components/Profile";
const Profile = () => {
  return (
    <React.Fragment>
      <Profilecmp> </Profilecmp>
    </React.Fragment>
  );
};

export default Profile;
